import { Component, BaseComponent } from '@zento-lib/components';
import { NewsletterStore } from 'theme/stores/newsletter/newsletter';

@Component({})
export class NewsletterMixin extends BaseComponent<unknown, unknown> {
  protected static T = {
    success: 'footer_newsletter_succes_message',
    unsubscribeSuccess: 'footer_newsletter_unsubscribe_succes_message',
    errorMessage: 'footer_newsletter_error_message',
  };

  protected newsletterStore = new NewsletterStore();

  async subscribeNewsletter(email: string) {
    const subscribed = await this.newsletterStore.subscribeEmailToNewsletter(email);

    if (subscribed === true) {
      if (this.$store.state.user.current) {
        this.$store.state.user.current.extension_attributes.is_subscribed = false;
      }

      this.onSucces();
    } else {
      this.onFail(subscribed);
    }
  }

  async unsubscribeNewsletter(customerId: number) {
    const unsubscribed = await this.newsletterStore.unsubscribeEmailToNewsletter(customerId);

    if (unsubscribed === true) {
      if (this.$store.state.user.current) {
        this.$store.state.user.current.extension_attributes.is_subscribed = false;
      }

      this.onSucces();
    } else {
      this.onFail(unsubscribed);
    }
  }

  private onSucces() {
    if (this.newsletterStore.customerNewsletterStatus === 'SUBSCRIBED' && this.newsletterStore.errorMessage === '') {
      if (!this.newsletterStore.drawerState) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: this.getTranslation({ id: NewsletterMixin.T.success }),
        });
      }
    } else if (
      this.newsletterStore.customerNewsletterStatus === 'UNSUBSCRIBED' &&
      this.newsletterStore.errorMessage === ''
    ) {
      if (!this.newsletterStore.drawerState) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: this.getTranslation({ id: NewsletterMixin.T.unsubscribeSuccess }),
        });
      }
    } else if (this.newsletterStore.errorMessage !== '') {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.getTranslation({ id: this.newsletterStore.errorMessage }),
      });
    } else {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.getTranslation({ id: NewsletterMixin.T.errorMessage }),
      });
    }
  }

  private onFail(e: Error) {
    this.$store.dispatch('notification/spawnNotification', {
      type: 'success',
      message: e.message,
    });
  }
}
